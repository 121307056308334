import React from "react"
import { Link } from "gatsby"

import Faq from "../core/FAQs"
import PageSupport from "./PageSupport"

import "../homepage/layout.css"

const faqs = [
  {
    question: "How are you different from other scheduling platforms?",
    answer: (
      <>
        <p key="faq-1">
          We offer the ability for your clients to book and pay for an unlimited
          number of 1:1 appointments as well as group sessions, classes, and
          events with you. We also allow them to be schedule time with you via
          your Facebook business page, or as a chat on your website.{" "}
        </p>
        <p key="faq-1b">
          The Sparkle Assistant can answer FAQs from your clients or upsell your
          products/services during the booking process to help you increase your
          revenues. We do MORE than just scheduling. You can share all of your
          links, content, lead magnets, freebies, downloadables, products, or
          services on your Sparkle Site. People even use the virtual tip jar to
          collect donations or sell gift cards.{" "}
        </p>
        <p key="faq-1c">
          In general, our customers consistently tell us that they chose We
          Sparkle because of its all-in-one nature, ease of use, and the
          messaging capabilities. Our subscribers report saving 1-5 hours/week
          in time. Plus, we are a responsive team ready to listen to your
          feedback and integrate improvements to make our tools more useful for
          you.
        </p>
      </>
    ),
  },
  {
    question: "What are your prices?",
    answer: [
      <p key="faq-2">
        Our pricing & platform solutions can be found{" "}
        <Link to="/pricing">here</Link>.
      </p>,
    ],
  },
  {
    question: "Is We Sparkle a nonprofit organization?",
    answer: [
      <p key="faq-3">
        While we are not a nonprofit, we are a social enterprise with a mission
        to build a stronger + more inclusive economy by equipping underestimated
        entrepreneurs with the AI-powered tools they need to succeed.
      </p>,
      <p key="faq-3b">
        We are a woman and BIPOC owned public benefit corporation, and we give
        back to people and planet through our community scholarships and Rate to
        Donate program.
      </p>,
    ],
  },
  {
    question: "How can I get help with my We Sparkle account?",
    answer: [
      <p key="faq-4">
        Our team is here to help! Contact us at{" "}
        <a href="mailto: support@wesparkle.ai">support@wesparkle.ai</a>.
      </p>,
    ],
  },
  {
    question: "What happens if the Assistant can't answer a question?",
    answer: [
      <p key="paragraph1">
        The Sparkle AI Assistant asks customers for their email at the beginning
        of the conversation. If someone asks a question it can't answer, it will
        respond with{" "}
        <i>&quot;I&apos;m sorry I am not able to find an answer.&quot;</i>
      </p>,
      <p key="paragraph1-a">
        Customers can customize this message in their We Sparkle Account
        Settings.
      </p>,

      <p key="paragraph2">
        You can choose to receive immediate email notifications in this occurs,
        and respond accordingly via email. Or, you can review the conversations
        that your Assistant had once a day if you sign up for the Daily Summary
        emails sent out each day. If you notice that a question has not been
        answered by the Assistant, you can follow up with that contact via
        email.
      </p>,
    ],
  },
  {
    question: "What payment platforms do you integrate with?",
    answer: [
      <p key="paragraph3">
        At this time, we integrate with Stripe. We may offer additional
        platforms in the future. Please email{" "}
        <a key="mailtoCommunity" href="mailto:community@wesparkle.co">
          community@wesparkle.co
        </a>{" "}
        if you would like to nominate a payment platform for us to add!
      </p>,
    ],
  },
  {
    question: "Do you offer product fulfillment?",
    answer: (
      <p key="paragraph4">
        We do not. If a customer purchases an item from your shop, you will
        receive an email with the order information and can send the purchased
        item.
      </p>
    ),
  },
  {
    question: "Can I sync my Sparkle Scheduler with my existing calendar?",
    answer: (
      <p key="paragraph5">
        You can sync your Sparkle calendar with your Google, Outlook or iCloud
        calendars. You can also integrate your Zoom account so that meeting
        links are automatically generated and included in the booking
        confirmations and reminders.
      </p>
    ),
  },
  {
    question: "Do I need a website if I have a Sparkle Site?",
    answer: (
      <>
        <p key="paragraph6">
          Many of our customers use their Sparkle Site, in lieu of a website.
          It’s easy to update and simple to set up.
        </p>
        <p key="paragraph6a">
          Even when our Sparklers have a website, many of them use their Sparkle
          Site as the link in their bio on Instagram or other social media
          because it’s easier to share multiple links and change up the content.
        </p>
        <p key="paragraph6b">
          <a key="schedule" href="https://sprkl.es/melissa/">
            Schedule a time
          </a>{" "}
          with our team to discuss further!
        </p>
      </>
    ),
  },
  {
    question: "Can I embed my Sparkle Site on my existing website?",
    answer: (
      <p key="paragraph7">
        Yes! More details on how to do this can be found{" "}
        <a
          key="embed-site"
          href="https://community.sprkl.es/portal/en/kb/articles/embed-we-sparkle-on-your-website"
        >
          here
        </a>
        .
      </p>
    ),
  },
  {
    question: "Do you offer any discounts?",
    answer: (
      <>
        <p key="paragraph8">
          Follow us on social at @wesparkleco to stay updated on deals and
          promos. We also offer{" "}
          <a href="https://wesparkle.co/about/#scholarships">
            community scholarships
          </a>{" "}
          and work with groups that work with small business owners like
          economic development organizations, coworking communities, incubators
          and nonprofits.
        </p>
        <p key="paragraph9">
          Reach out to{" "}
          <a key="communityemail" href="mailto:community@wesparkle.co">
            community@wesparkle.co
          </a>{" "}
          to talk more.
        </p>
      </>
    ),
  },
]

const Page = () => {
  return (
    <>
      <PageSupport />
      <div id="faqs-section">
        <Faq faqs={faqs} />
      </div>
    </>
  )
}

export default Page
