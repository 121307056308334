import React from "react"
import Seo from "../../core/Seo"
import SupportLayout from "../../support/Page"
import { Content } from "../../core/LayoutComponents"

const SupportPage = () => {
  return (
    <Content>
      <Seo
        title="We Sparkle • Let's Connect! | Contact Us"
        includeZohoTracking
      />
      <SupportLayout />
    </Content>
  )
}

export default SupportPage
