import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { ButtonBase } from "../homepage/Buttons"
import { segmentAction } from "../hooks/useSegmentTrack"

const options = [
  {
    accentColor: "var(--sparkle-yellow)",
    name: "General Questions",
    description: [
      <p key="question-1a">
        Questions? Interested in an enterprise customization? Drop us a line!
      </p>,

      <p key="question-1b">
        You can also type your question in the chat for our Sparkle Assistant to
        answer.
      </p>,
    ],
    cta: "Contact Us",
    ctaUrl: "mailto:support@wesparkle.ai",
  },
  {
    accentColor: "var(--sparkle-green)",
    name: "Technical Support",
    description: [
      <p key="question-2a">Need help? Questions about setting up your Sparkle Site, Scheduler, or Assistant? </p>,
      <p key="question-2b">View our help site. Our team is here for you!</p>,
    ],
    cta: "Visit Help Site",
    ctaUrl: "https://community.sprkl.es/portal/en/kb",
  },
  // {
  //   accentColor: "var(--sparkle-brightblue)",
  //   name: "Schedule a Demo",
  //   description: [
  //     <p key="question3a">
  //       Join us for our monthly office hours! Bring your tech questions, We
  //       Sparkle or otherwise.
  //     </p>,
  //     <p key="question3b">
  //       Get a demo, get help setting up your account, or just say hi (we're
  //       nice!)
  //     </p>,
  //   ],
  //   cta: "Schedule a Demo",
  //   ctaUrl:
  //   "https://sprkl.es/melissa/?project=5e30d961-ff4e-445c-bf4a-a4dbbab38f85#bookings",
  // },
  {
    accentColor: "var(--sparkle-brightblue)",
    name: "Schedule a Demo",
    description: [
      <p key="question3a">Interested in working with We Sparkle?</p>,
      <p key="question3b">
        Let's meet so we can learn about your needs and demo how we can help
        your business or organization!
      </p>,
    ],
    cta: "Schedule a Demo",
    ctaUrl: "/enterprise",
  },
  {
    accentColor: "var(--sparkle-white)",
    name: "Book Michelle",
    description: [
      <p key="question4a">
        Michelle is an accomplished speaker and expert in starting a business, AI technology, and social entrepreneurship.
      </p>,
    ],
    cta: "Book Michelle",
    internalUrl: true,
    ctaUrl: "/contact/book-michelle",
  },
]

const PageContact = () => {
  return (
    <>
      <h1>Let's Connect!</h1>
      <Container>
        {options.map(
          (
            {
              accentColor: color,
              name,
              description,
              cta,
              ctaUrl,
              internalUrl,
              key,
            },
            i
          ) => {
            return (
              <Option color={color} key={i + name}>
                <Border color={color} className={"pos-full"} />
                <div>
                  <OptionHeader color={color}>{name}</OptionHeader>
                  <OptionDescription>{description}</OptionDescription>
                </div>
                {!internalUrl ? (
                  <ExternalLink
                    as={"a"}
                    href={ctaUrl}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    bg={color}
                    onClick={() => {
                      segmentAction.track(`Support Page Contact Click`, {
                        value: name,
                      })
                    }}
                  >
                    {cta}
                  </ExternalLink>
                ) : (
                  <Link
                    to={ctaUrl}
                    span={"one"}
                    style={{
                      display: "inline-block",
                      position: "relative",
                      borderRadius: "100px",
                      background: color,
                      color: "var(--sparkle-darkblue)",
                      padding: "0.45rem 0.5rem",
                      fontWeight: "bold",
                      fontSize: "1rem",

                      letterSpacing: "0.02em",
                      textTransform: "uppercase",
                      margin: "1.5rem 0 0 0",
                      transition: "0.15s",
                      cursor: "pointer",
                    }}
                  >
                    {cta}
                  </Link>
                )}
              </Option>
            )
          }
        )}
      </Container>
    </>
  )
}

export default PageContact

const Border = styled.div`
  border: 2px solid ${({ color }) => color};
  transition: 0.3s;
  opacity: 0.5;
  border-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
`
const OptionHeader = styled.h2`
  color: ${({ color }) => color};
`
const OptionDescription = styled.div`
  margin: 1.5rem 0;
  text-align: left;
  font-size: 1rem;
  p {
    margin-block: 0 1rem;
  }
`
const ExternalLink = styled(ButtonBase)`
  position: relative;
  transform: translateZ(0);
  width: 100%;
  opacity: 1;
  color: var(--sparkle-darkblue) !important;
  padding: 0.7em 0.5em;
  font-weight: bold;
  font-size: 1rem;
  background: ${({ bg }) => bg};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border-radius: 100px;
  margin: 1.5rem 0rem 0rem;
  transition: 0.15s;
  cursor: pointer;
`

const Container = styled.div`
  margin-block: 1rem;
  display: flex;
  gap: 2rem;
  padding-inline: 1rem;

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 3rem;
  }
`
const Option = styled.article`
  position: relative;
  text-align: center;
  padding: 2rem 1.5rem;
  flex: 300px;
  transform: translateZ(0);
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :hover {
    transform: translateY(-5px);
    ${Link} {
      opacity: 1;
      transform: scale(1.01) translateY(-1px) translateZ(0);
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
      span {
        transform: translateY(-50%);
        opacity: 1;
      }
    }
    ${Border} {
      opacity: 1;
    }
  }
  @media screen and (max-width: 900px) {
    padding: 1.5rem;
  }
`
